<template>
	<b-modal
		id="reviewDialog"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		size="xl"
		no-close-on-backdrop
		no-close-on-esc
		hide-backdrop
		:ok-only="!isUpdateAndExternal"
		:ok-title="okTitleLabel"
		:ok-variant="okVariant"
		:ok-disabled="loadingForMarkReview || loadingForSubmit"
		:cancel-disabled="loadingForSubmit"
		cancel-variant="outline-primary"
		@ok.prevent="handleOk"
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@show="emitEventShow"
		modal-class="mui-animation"
		:fade="false"
	>
		<template #modal-title>
			<b-row>
				<b-col cols="4">
					{{ titleCaption }}
				</b-col>
				<b-col v-if="isCreate === false && dataLength > 1" cols="4" class="pl-5">
					<div class="d-flex align-items-center justify-content-center">
						<div>
							<button
								class="btn-transparent text-color-rhapsody-in-blue"
								:disabled="disableBtnBefore || loadingForMarkReview || loadingForSubmit"
								@click="handleClickBefore"
							>
								<component :is="getLucideIcon('ChevronLeft')" />
							</button>
						</div>
						<div>
							<div class="text-color-rhapsody-in-blue fs-16 fw-40">{{ FormMSG(45, 'Entry') }} {{ currentIndex }} / {{ dataLength }}</div>
						</div>
						<div>
							<button
								class="btn-transparent text-color-rhapsody-in-blue"
								:disabled="disableBtnNext || loadingForMarkReview || loadingForSubmit"
								@click="handleClickAfter"
							>
								<component :is="getLucideIcon('ChevronRight')" />
							</button>
						</div>
					</div>
				</b-col>
			</b-row>
		</template>
		<div ref="content-review-dialog" id="content-review-dialog">
			<b-row class="mb-2">
				<b-col sm="12" md="4" lg="4" xl="4" class="text-center">
					<b-button v-if="imageUrls.length > 0" variant="primary" class="mr-2" @click="onPickFile">
						<component :is="getLucideIcon('Plus')" :size="16" /> {{ FormMSG(861, 'Add picture') }}
					</b-button>
					<b-button variant="success" disabled> <component :is="getLucideIcon('Camera')" :size="16" /> {{ imageUrls.length }} </b-button>
				</b-col>
				<b-col sm="12" md="8" lg="8" xl="8">
					<div class="float-right">
						<div class="d-flex">
							<div class="wrap-status">
								<div
									class="status fw-700"
									:class="{
										validated: carbonData.verified === 3,
										'not-submitted': carbonData.verified === 0,
										pending: carbonData.verified === 2,
										info: carbonData.verified === 1
									}"
									style="font-size: 0.7rem"
								>
									<div class="text-center">
										{{ getLabelVerifiedStatus(carbonData.verified) }}
									</div>
								</div>
							</div>
							<div v-if="showContextStatus" class="d-flex justify-content-center" style="min-width: 60px">
								<b-dropdown id="dropdown-green-action" no-caret dropleft boundary="window" class="btn-transparent" variant="none" size="sm">
									<template #button-content>
										<component :is="getLucideIcon('MoreVertical')" :size="16" />
									</template>

									<b-dropdown-item v-if="showResetStatusMenu" @click="handleClickResetStatus">
										<span class="text-color-rhapsody-in-blue">
											<component :is="getLucideIcon('RotateCcw')" :size="16" />&nbsp;{{ FormMSG(289, 'Reset status') }}
										</span>
									</b-dropdown-item>
									<b-dropdown-item v-if="carbonData.templateId > 0" @click="handleClickViewAutomation(carbonData.templateId)">
										<span class="text-color-rhapsody-in-blue">
											<component :is="getLucideIcon('Regex')" :size="16" />&nbsp;{{ FormMSG(291, 'View automation') }}
										</span>
									</b-dropdown-item>
								</b-dropdown>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<perfect-scrollbar id="content-review-dialog-details">
				<b-row>
					<b-col v-if="imageUrls.length > 0" sm="12" md="4" lg="4" xl="4" class="details-document-package">
						<carousel :pagination-enabled="false" :per-page="1" navigation-enabled autoplay-direction="backward">
							<slide v-for="(img, i) in imageUrls" :data-log="$log({ imageUrls })" :key="i">
								<div
									class="doc-list-package size-2"
									style="height: 100%; width: 100%; position: relative"
									@mouseenter="handleMouseEnter(img, i)"
									@mouseleave="handleMouseLeave(img, i)"
								>
									<div
										v-if="img.extension !== 'pdf'"
										class="doc-list-package-action"
										style="max-height: 100%; width: 100%"
										:style="`height: ${img.hoverAction ? '100%' : '0'}`"
									>
										<div
											class="container-doc-list-package-action"
											:class="{
												'd-block': img.hoverAction,
												'd-none': !img.hoverAction
											}"
										>
											<div class="d-flex">
												<button type="button" class="btn-transparent" @click="openNewTab(i)">
													<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="1.5" color="#00A09C" />
												</button>
												<button type="button" class="btn-transparent text-color-burning-tomato" @click="handleDeletePicture(i)">
													<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="1.5" />
												</button>
											</div>
										</div>
									</div>

									<div v-if="forCreate === true">
										<img
											v-if="['jpg', 'jpeg', 'png'].includes(img.extension)"
											:src="img.imgSrc"
											:alt="img.imgSrc"
											class="location-slide-img"
											style="height: 100% !important; width: 100% !important"
										/>
										<img
											v-else
											src="img/thumb-not-found.png"
											:alt="img.imgSrc"
											class="location-slide-img"
											style="height: 100% !important; width: 100% !important"
										/>
									</div>
									<div v-else :style="img.extension === 'pdf' ? 'width: 100%; height: 100%' : ''">
										<div v-if="['jpg', 'jpeg', 'png'].includes(img.extension)">
											<img
												:src="img.imgSrc"
												:alt="img.imgSrc"
												class="location-slide-img"
												style="height: 100% !important; width: 100% !important"
											/>
										</div>
										<div v-else-if="img.extension === 'pdf'" style="width: 100%; height: 100%; padding-bottom: 35px">
											<b-overlay :show="img.loading" opacity="0.85" style="height: 100%">
												<iframe class="full full_h clearfix" :src="img.imgSrc" frameborder="0" @load="onFrameLoaded(i)" />
												<template #overlay>
													<div class="text-center bg-transparent">
														<loading :active.sync="img.loading" loader="bars" color="#00A09C" :width="64" />
														<p class="loading-text">
															{{ FormMSG(100, 'Please wait...') }}
														</p>
													</div>
												</template>
											</b-overlay>
											<div class="mt-2">
												<b-button variant="danger" size="sm" class="float-right" @click="handleDeletePicture(i)">
													<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="1.5" /> {{ FormMSG(899, 'Remove file') }}
												</b-button>
											</div>
										</div>
										<div v-else style="height: 100%; width: 100%">
											<span v-lazy-container="{ selector: 'img' }">
												<img
													:data-src="getImageName(img.xid)"
													data-error="img/thumb-not-found.png"
													data-loading="img/brand/tenor.gif"
													alt="pict"
													class="location-slide-img"
													style="height: 100% !important; width: 100% !important"
												/>
											</span>
										</div>
									</div>
								</div>
							</slide>
						</carousel>
					</b-col>
					<b-col v-else sm="12" md="4" lg="4" xl="4" class="details-document-package">
						<div class="doc-list-package size-2" style="height: 100%; width: 100%; position: relative">
							<div class="add-img" style="width: 100%; height: 100%" @click="onPickFile">
								<div class="center-content">
									<div class="text-color-rhapsody-in-blue">
										<component :is="getLucideIcon('PlusCircle')" :size="20" color="#00A09C" />
									</div>
								</div>
							</div>
						</div>
					</b-col>
					<input type="file" style="display: none" ref="additional-detail-file" @change="onFilePicked" />
					<b-col sm="12" :md="8" :lg="8" :xl="8">
						<main-detail-for-review
							:source="sourceType"
							:data-to-review="dataToEdit"
							:for-create="forCreate"
							:disable-form="disableForm"
							@main-detail-for-review:change="handleMainDetailForReviewChange"
						/>

						<additional-detail
							:title="titleAdditionalDetail"
							:source="sourceType"
							:for-create="disableForm"
							:data-to-review="dataToEdit"
							@additional-detail:change="handleAdditionalDetailChange"
							@additional-detail:image-picked="handleAdditionalDetailImagePicked"
						/>

						<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2 fw-400">
								{{ titleGreenDetail }}
							</legend>
							<div class="pt-3 pb-4">
								<co-two-clap
									ref="coTwoClapRef"
									:parent-id="entityId"
									:entity-type="sourceType"
									:for-create="!isEditMode"
									:is-submit="submitCarbon"
									:external-data="dataForCarbon"
									:external-carbon-data="carbonData"
									:disable-transport-forms="disableTransportForms"
									:disable-accomodation-forms="disableAccomodationForms"
									:disable-carbon-type-selector="disableCarbonSelector"
									:use-address-transport-string="useAddressTransportString"
									:hide-distance-known-transport="hideDistanceKnownForExpenseMileage"
									:hide-premise-menu="forCreate === true"
									@co-two-clap:reviewed="handleClickReviewed"
									@co-two-clap:init-carbon="handleCoTwoClapInitCarbon"
									@co-two-clap:change="handleCoTwoClapChange"
								/>
							</div>
						</fieldset>

						<b-row v-if="forCreate === false && dataToEdit.verified === false" class="mt-3">
							<b-col>
								<b-button variant="primary" class="pl-4 pr-4" :disabled="loadingForMarkReview || loadingForSubmit" @click="handleClickReviewed">
									<component v-if="loadingForMarkReview === false" :is="getLucideIcon('CheckCircle')" :size="16" />
									<b-spinner v-if="loadingForMarkReview === true" small />
									{{ FormMSG(48, 'Mark as reviewed') }}
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</perfect-scrollbar>

			<carbon-template-dialog
				:open="showCarbonTemplateDialog"
				:data-to-edit="carbonTemplateData"
				:raw-text-data="rawTextAutomation"
				@carbon-template-dialog:close="handleCarbonTemplateDialogClose"
				@carbon-template:submit-success="handleCarbonTemplateSubmitSuccess"
			/>
		</div>
		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="md"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="loadingForSubmit || loadingForRunAutomate"
					@click="cancel"
					block
				>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button variant="custom-primary-blue" class="mr-3" :disabled="loadingForSubmit || loadingForRunAutomate" @click="handleClickRunAutomation">
					<b-spinner v-if="loadingForRunAutomate" small class="mr-3" />
					<component v-if="!loadingForRunAutomate" :is="getLucideIcon('Play')" :size="16" />
					{{ FormMSG(197, 'Run automations') }}
				</b-button>
				<b-button
					v-if="!forCreate"
					size="md"
					variant="success"
					style="margin-top: 5px"
					class="mr-3"
					:disabled="loadingForSubmit || loadingForRunAutomate"
					@click="handleClickCreateCarbonTemplate"
				>
					{{ FormMSG(168, 'Set as template for new automation') }}
				</b-button>

				<b-button size="md" :variant="okVariant" class="w-138-px" :disabled="loadingForSubmit || loadingForRunAutomate" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingForSubmit" small />
						<div :class="`${loadingForSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ okTitleLabel }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import MainDetailForReview from './MainDetailForReview';
import AdditionalDetail from './AdditionalDetail';
import GreenDetail from './GreenDetail';
import CoTwoClap from './CoTwoClap';
import CapturesPackage from '@/components/Packages/Captures/components/Main';

import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { getExpenseItem } from '@/cruds/expense.crud';
import {
	runAutomation,
	addUpdCarbonFromExternalSource,
	getCarbonFromExternalSource,
	updCarbon,
	getCarbonTemplate,
	changeCarbonVerifiedStatus
} from '@/cruds/carbon.crud';
import { store } from '@/store';
import { Carousel, Slide } from 'vue-carousel';
import { getFileExtension } from '@/shared/helpers';
import CarbonTemplateDialog from '@/modules/carbonclap/components/carbon-template/CarbonTemplateDialog.vue';
import { getLocation } from '@/cruds/locations.crud';
import { getTransport } from '@/cruds/transports.crud';
import { getAccomodation } from '@/cruds/accomodation.crud';
import _ from 'lodash';
import { getWasteDetail } from '@/cruds/waste.crud';
import { CARBON_VERIFIED_STATUS_MENU } from '@/shared/constants';
import AdobePdfReaderViewer from '@/components/Packages/FIlesPreviewer/components/FileViewers/AdobePdfReaderViewer.vue';
import ImageShowRoomManager from '@/components/Packages/FIlesPreviewer/imageShowroom.manager';
import Loading from 'vue-loading-overlay';
import { INIT_DATA_CARBON, EXPENSE_TYPE } from '@/shared/constants';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { getBudgetDetail } from '@/cruds/categorie.crud';

const SOURCE_TYPES = ['', 'location', 'accomodation', 'expense'];
const INIT_DATA_TO_EDIT = {
	id: 0,
	description: '',
	carbonDateTime: new Date(),
	forForecastReportOnly: false,
	documentXid: [],
	createdAt: new Date().toISOString(),
	user: { name: '', firstName: '' },
	amount: 0,
	amountVat: 0,
	amountTotal: 0,
	supplierId: null,
	category: null,
	supplier: { name: '' },
	costCenter: '',
	carbon: {
		carbonValues: {
			kgCoTwo: 0
		}
	}
};

const INIT_CARBON_TEMPLATE_DATA = {
	id: 0,
	supplierId: 0,
	keyWordOne: '',
	keyWordTwo: '',
	keyWordThree: '',
	searchInDescOnly: true,
	coTwoCategory: 1,
	type: 0,
	subType: 0,
	additionalSubType: 0,
	vehiculeId: 0,
	reason: 0,
	km: 0,
	liter: 0,
	mcube: 0,
	kwh: 0,
	msquare: 0,
	number: 0,
	boolValue: false,
	mealRed: 0,
	mealWhite: 0,
	mealVege: 0,
	mealNotSpecified: 0,
	unitAmountTotal: 0,
	costCenters: '',
	locationId: 0,

	averageWasteCardboard: 0,
	averageWastePaper: 0,
	averageWastePlastic: 0,
	averageWasteFood: 0,
	averageWasteGeneral: 0,
	averageWastePaint: 0,
	averageWasteWood: 0,
	averageWasteSteel: 0,
	averageWasteBatterie: 0,

	averageElec: 0,
	averageGas: 0,
	averageWater: 0,
	averageGeneratorUnlead: 0,
	averageGeneratorGas: 0,
	averageGeneratorHydrogene: 0,
	averageTransportation: 0,

	averageMealVege: 0,
	averageMealRed: 0,
	averageMealWhite: 0,
	averageMealNotSpecified: 0
};

const axios = require('axios').create();

export default {
	name: 'ReviewDialog',

	mixins: [languageMessages, globalMixin],

	components: {
		Loading,
		CarbonTemplateDialog,
		MainDetailForReview,
		AdditionalDetail,
		GreenDetail,
		CoTwoClap,
		CapturesPackage,
		Carousel,
		Slide,
		AdobePdfReaderViewer,
		PerfectScrollbar
	},

	props: {
		open: { type: Boolean, default: false },
		forCreate: { type: Boolean, default: true, required: false },
		source: { type: Number, default: 1, required: false },
		dataToReview: { type: Object, default: () => ({}), required: false },
		dataLength: { type: Number, default: 0 },
		currentIndex: { type: Number, default: 0 }
	},

	data() {
		const manager = new ImageShowRoomManager(this, {});
		return {
			manager,
			isCreate: false,
			sourceType: 1,

			dataToEdit: _.cloneDeep(INIT_DATA_TO_EDIT),
			entityId: 0,

			loadingForMarkReview: false,
			loadingForSubmit: false,
			loadingForRunAutomate: false,

			dataLocationToUpload: null,
			submitCarbon: false,

			dataForCarbon: {
				description: ''
			},

			fileToInsertExternal: null,
			filesToInsert: [],

			imageUrl: '',
			imageUrls: [],

			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			carbonTemplateData: INIT_CARBON_TEMPLATE_DATA,
			showCarbonTemplateDialog: false,

			rawTextAutomation: {},
			haveRunAutomateExecuted: false
		};
	},

	watch: {
		dataToReview: {
			async handler(newVal) {
				const actionForLoader = async () => {
					if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
						await this.initEntryDataAndCarbon(newVal, true);
					}
				};

				await this.showLoader(actionForLoader, 'content-review-dialog');
			},
			deep: true,
			immediate: true
		},
		source: {
			handler(newVal) {
				this.sourceType = newVal;
			},
			deep: true,
			immediate: true
		},
		forCreate: {
			handler(newVal) {
				this.isCreate = newVal;
			},
			deep: true,
			immediate: true
		}
	},

	computed: {
		showContextStatus() {
			if (this.showResetStatusMenu === false && this.carbonData.templateId === 0) {
				return false;
			}

			return true;
		},

		showResetStatusMenu() {
			if ((this.carbonData.entityType === 1 || this.carbonData.entityType === 8) && this.carbonData.verified > 0) {
				return true;
			} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(this.carbonData.entityType) && this.carbonData.verified !== 2) {
				return true;
			}

			return false;
		},
		useAddressTransportString() {
			if (this.source === 6 && this.forCreate === false) {
				return true;
			} else if (this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL && this.forCreate === false) {
				return true;
			}

			return false;
		},
		hideDistanceKnownForExpenseMileage() {
			if (this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL && this.forCreate === false) {
				return true;
			}

			return false;
		},
		disableCarbonSelector() {
			if (
				(this.source === 2 ||
					this.source === 5 ||
					this.source === 10 ||
					this.source === 6 ||
					this.source === 9 ||
					(this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL)) &&
				this.forCreate === false
			) {
				return true;
			}

			return false;
		},
		disableTransportForms() {
			if (this.source === 2 && this.forCreate === false) {
				return true;
			} else if (this.source === 6 && this.dataToEdit.category === 1 && this.forCreate === false) {
				return true;
			} else if (this.source === 1 && this.dataToEdit.type === EXPENSE_TYPE.TRAVEL && this.forCreate === false) {
				return true;
			}

			return false;
		},
		disableAccomodationForms() {
			return this.source === 6 && this.forCreate === false;
		},
		disableForm() {
			return this.source !== 8;
		},
		isUpdateAndExternal() {
			return this.forCreate === false;
		},
		isEditMode() {
			return JSON.stringify(this.dataToReview) !== '{}' && !_.isNil(this.dataToReview);
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(newVal) {
				return newVal;
			}
		},
		okVariant() {
			if (this.isCreate === true) {
				return 'primary';
			} else {
				if (this.isUpdateAndExternal) {
					return 'primary';
				} else {
					return 'custom-primary-gray';
				}
			}
		},
		okTitleLabel() {
			if (this.isCreate === true) {
				return this.FormMSG(149, 'Add entry');
			} else {
				if (this.isUpdateAndExternal) {
					return this.FormMSG(581, 'Update');
				} else {
					return this.FormMSG(150, 'Close');
				}
			}
		},
		disableBtnBefore() {
			return this.currentIndex === 1;
		},
		disableBtnNext() {
			return this.currentIndex === this.dataLength;
		},
		titleCaption() {
			if (this.isCreate === true) {
				return this.FormMSG(20, 'New Green Entry');
			} else if (this.isCreate === false) {
				return this.FormMSG(21, 'Green entry review');
			}
		},

		titleAdditionalDetail() {
			if (this.isCreate === true) {
				return this.FormMSG(26, 'Additional detail');
			} else {
				switch (this.sourceType) {
					case 5:
						return this.FormMSG(40, 'Location detail');
					case 6:
						return this.FormMSG(41, 'Accomodation detail');
					case 1:
						return this.FormMSG(42, 'Expense detail');
					default:
						return this.FormMSG(49, 'Detail');
				}
			}
		},

		titleGreenDetail() {
			return this.FormMSG(30, 'Green detail');
		}
	},

	methods: {
		async handleClickRunAutomation() {
			const actionForLoader = async () => {
				this.loadingForRunAutomate = true;
				// this.haveRunAutomateExecuted = true;

				await runAutomation(null, true, [+this.dataToReview.id]);

				await this.initEntryDataAndCarbon(this.dataToReview, false);

				this.emitEventRunAutomation();

				await this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(841, 'Automation executed successfully!'));

				this.loadingForRunAutomate = false;
			};

			await this.showLoader(actionForLoader, 'content-review-dialog');
		},
		emitEventRunAutomation() {
			this.$emit('review-dialog:automation-end', {
				carbonData: this.carbonData,
				currentIndex: this.currentIndex - 1
			});
		},
		async initEntryDataAndCarbon(newVal, useOmit = true) {
			this.sourceType = newVal.entityType;
			this.entityId = this.dataToReview.entityId;

			let fieldOmittedCarbonData =
				useOmit === true ? ['__typename', 'carbonValues', 'department', 'function', 'supplier', 'user', 'updatedAt', 'createdAt'] : [];

			if (newVal.entityType === 8) {
				const result = await getCarbonFromExternalSource(parseInt(this.dataToReview.entityId, 10));

				this.dataToEdit = {
					...result,
					costCenter: result.carbon.costCenter
				};

				if (+result.parentId > 0) {
					const resultParent = await getCarbonFromExternalSource(+result.parentId);

					this.dataToEdit.documentXid = _.concat(this.dataToEdit.documentXid, resultParent.documentXid);
					this.dataToEdit.carbon.rawText = resultParent.carbon.rawText;
				}

				if (this.dataToEdit.carbon.rawText !== '') {
					this.rawTextAutomation = { text: this.dataToEdit.carbon.rawText };
				}

				this.dataToEdit.costCenter = result.carbon.costCenter;

				this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);

				this.prepareImageUrls(this.dataToEdit.documentXid);
			} else if (newVal.entityType === 1) {
				const result = await getExpenseItem(parseInt(this.dataToReview.entityId, 10));
				this.dataToEdit = {
					...result,
					description: result.carbon.description,
					createdAt: result.date,
					supplierName: result.supplierName,
					supplierId: result.supplierId,
					user: result.user,
					amount: result.amountTotal,
					supplier: result.supplier,
					documentXid: result.images,
					costCenter: result.carbon.costCenter
				};

				this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);

				this.prepareImageUrls(result.images);
			} else if (newVal.entityType === 5 || newVal.entityType === 10) {
				const result = await getLocation(this.dataToReview.entityId);
				const carbonDataFinded = result.carbon.find((option) => +option.id === +this.dataToReview.id);
				this.dataToEdit = {
					...result,
					description: result.setName,
					createdAt: result.createdAt,
					supplierName: '',
					supplierId: 0,
					user: null,
					amount: 0,
					supplier: null,
					documentXid: result.images,
					type1: result.type1,
					type: result.type,
					address: result.address5,
					filmingArea: result.filmingArea,
					filmingDays: result.locationDates.length,
					costCenter: carbonDataFinded.costCenter,
					consumptionDate: carbonDataFinded.strDate
				};

				this.carbonData = _.omit(carbonDataFinded, fieldOmittedCarbonData);

				this.prepareImageUrls(result.images);
			} else if (newVal.entityType === 2) {
				const result = await getTransport(this.dataToReview.entityId);
				this.dataToEdit = {
					...result,
					description: result.extraInfo,
					createdAt: result.departureDate,
					supplierName: '',
					supplierId: 0,
					user: result.user,
					amount: 0,
					supplier: null,
					costCenter: result.carbon.costCenter
				};

				this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);

				this.prepareImageUrls(result.documentXid);
			} else if (newVal.entityType === 6) {
				const result = await getAccomodation(this.dataToReview.entityId);
				this.dataToEdit = {
					...result[0],
					description: result[0].description,
					createdAt: new Date(),
					supplierName: '',
					supplierId: 0,
					user: null,
					amount: 0,
					documentXid: [],
					costCenter: result[0].carbon.costCenter
				};

				this.carbonData = _.omit(result[0].carbon, fieldOmittedCarbonData);
			} else if (newVal.entityType === 9) {
				const result = await getWasteDetail(this.dataToReview.entityId);
				this.dataToEdit = {
					...result,
					description: result.carbon.description,
					createdAt: result.createdAt,
					supplierName: '',
					supplierId: 0,
					user: null,
					amount: 0,
					supplier: null,
					documentXid: [],
					costCenter: result.carbon.costCenter
				};

				this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
			} else if (newVal.entityType === 7) {
				const result = await getBudgetDetail(this.dataToReview.entityId);
				this.dataToEdit = {
					...result,
					description: result.description,
					createdAt: result.createdAt,
					supplierName: '',
					supplierId: 0,
					user: null,
					amount: 0,
					supplier: null,
					documentXid: [],
					costCenter: result.costCenter
				};

				this.carbonData = _.omit(result.carbon, fieldOmittedCarbonData);
			}
		},
		onFrameLoaded(index) {
			this.imageUrls[index].loading = false;
		},
		buildImagesList(images, focusIndex) {
			const imagesList = [];
			this.manager.dispatch('setImagesList', []);
			const e = process.env.VUE_APP_PATH_IMG_URL;
			for (let i = 0; i < images.length; i++) {
				const file = images[i];

				const el = _.isObject(file) ? file : { xid: file, src: e + file, ext: 'png' };

				imagesList.push(el);
			}
			this.manager.dispatch('setImagesList', imagesList);
			const __i = _.isNil(focusIndex) ? 0 : focusIndex;
			if (!_.isNil(imagesList[__i])) {
				this.manager.dispatch('getCommentStats', imagesList[__i].xid);
				this.setActiveCarouselImage(imagesList[__i]);
			}
		},
		setActiveCarouselImage(imgObj) {
			this.manager.states.currentActiveSlideExt = imgObj.ext;
			this.manager.dispatch('setCurrentActiveSlide', imgObj.xid);
		},
		async handleClickResetStatus() {
			let reviewStatusToSend = 0;

			if (this.carbonData.entityType === 1) {
				if (this.dataToEdit.type === EXPENSE_TYPE.TRAVEL) {
					reviewStatusToSend = 0;
					this.carbonData.verified = 0;
				} else {
					reviewStatusToSend = 0;
					this.carbonData.verified = 0;
					this.carbonData.coTwoCategory = 0;
				}
				/*
				if (this.carbonData.coTwoCategory === 4) {
					reviewStatusToSend = 2;
					this.carbonData.verified = 2;
				} else {
          */
				// reviewStatusToSend = 0;
				// this.carbonData.verified = 0;
				// this.carbonData.coTwoCategory = 0;
				//}
			} else if (this.carbonData.entityType === 8) {
				reviewStatusToSend = 0;
				this.carbonData.verified = 0;
				this.carbonData.coTwoCategory = 0;
			} else if ([2, 3, 4, 5, 6, 7, 9, 10].includes(this.carbonData.entityType)) {
				reviewStatusToSend = 2;
				this.carbonData.verified = 2;
			}

			await changeCarbonVerifiedStatus([+this.carbonData.id], reviewStatusToSend);

			// this.carbonData.verified = reviewStatusToSend;

			this.$emit('review-dialog:reset-status', { carbonData: this.carbonData });
			this.emitEventClose(false);

			this.createToastForMobile(this.FormMSG(258, 'Success'), this.FormMSG(259, 'Updated status successfully!'));
		},
		async handleClickViewAutomation(templateId) {
			this.carbonTemplateData = await getCarbonTemplate(+templateId);

			this.showCarbonTemplateDialog = true;
		},
		getLabelVerifiedStatus(value) {
			let menues = this.FormMenu(CARBON_VERIFIED_STATUS_MENU);
			return menues.find((option) => option.value === value).text;
		},
		openNewTab(index) {
			const url = `${process.env.VUE_APP_GQL}/images/${this.imageUrls[index].xid}`;
			window.open(url, '_blank');
		},
		getImageName(path) {
			const pathSplited = path.split('.')[0];
			return process.env.VUE_APP_GQL + '/imagethumb/' + path;
		},
		handleCarbonTemplateSubmitSuccess() {
			this.carbonTemplateData = _.cloneDeep(INIT_CARBON_TEMPLATE_DATA);
			this.showCarbonTemplateDialog = false;
		},
		handleCarbonTemplateDialogClose() {
			this.carbonTemplateData = _.cloneDeep(INIT_CARBON_TEMPLATE_DATA);
			this.showCarbonTemplateDialog = false;
		},
		handleCoTwoClapChange({ carbonData }) {
			this.carbonData = carbonData;
		},
		async handleClickCreateCarbonTemplate() {
			this.carbonTemplateData = this.mappingCarbonDataToCarbonTemplate();

			this.showCarbonTemplateDialog = true;
		},
		mappingCarbonDataToCarbonTemplate() {
			let carbonTemplate = _.cloneDeep(INIT_CARBON_TEMPLATE_DATA);

			carbonTemplate.keyWordOne = this.dataToEdit.description;
			carbonTemplate.searchInDescOnly = true;
			carbonTemplate.supplierId = this.dataToEdit.supplierId;
			carbonTemplate.userId = store.userID();
			carbonTemplate.coTwoCategory = +this.carbonData.coTwoCategory;
			carbonTemplate.boolValue = +this.carbonData.boolValue;
			carbonTemplate.type = +this.carbonData.type;
			carbonTemplate.locationId = +this.carbonData.locationId;
			carbonTemplate.costCenters = this.dataToEdit.category > 0 ? this.dataToEdit.costCenter : '';

			if (this.carbonData.coTwoCategory === 3) {
				carbonTemplate.subType = +this.carbonData.subType;
			} else if ([1, 5, 15].includes(this.carbonData.coTwoCategory)) {
				carbonTemplate.subType = 1;
			} else if (this.carbonData.coTwoCategory === 11) {
				carbonTemplate.subType = this.carbonData.subType;
			} else if (this.carbonData.coTwoCategory === 4) {
				if (this.carbonData.transport) {
					carbonTemplate.subType = this.carbonData.transport.subType;
					carbonTemplate.type = this.carbonData.transport.type;
					carbonTemplate.additionalSubType = this.carbonData.transport.additionalSubType;
					carbonTemplate.km = this.carbonData.transport.km;
					carbonTemplate.reason = this.carbonData.transport.reason;
					carbonTemplate.vehiculeId = this.carbonData.transport.vehiculeId;
					carbonTemplate.number = this.carbonData.transport.nbVehicle;
				}
			}
			if (this.carbonData.coTwoCategory === 5) {
				carbonTemplate.subType = 1;
				carbonTemplate.number = 1;
				if (this.dataToEdit.amount > 0) {
					if (this.carbonData.type === 1) {
						carbonTemplate.averageWasteCardboard = this.dataToEdit.amount / +this.carbonData.kgCardboardWaste;
						carbonTemplate.averageWastePaper = this.dataToEdit.amount / +this.carbonData.kgPaperWaste;
						carbonTemplate.averageWastePlastic = this.dataToEdit.amount / +this.carbonData.kgPlasticWaste;
						carbonTemplate.averageWasteFood = this.dataToEdit.amount / +this.carbonData.kgFoodWaste;
						carbonTemplate.averageWasteGeneral = this.dataToEdit.amount / +this.carbonData.kgGeneralWaste;
						carbonTemplate.averageWasteBatterie = this.dataToEdit.amount / +this.carbonData.unitBatterieWaste;
					} else if (this.carbonData.type === 2) {
						carbonTemplate.averageWastePaint = this.dataToEdit.amount / +this.carbonData.kgPaintWaste;
						carbonTemplate.averageWasteCardboard = this.dataToEdit.amount / +this.carbonData.kgCardboardWaste;
						carbonTemplate.averageWasteWood = this.dataToEdit.amount / +this.carbonData.kgWoodWaste;
						carbonTemplate.averageWasteSteel = this.dataToEdit.amount / +this.carbonData.kgSteelWaste;
						carbonTemplate.averageWastePlastic = this.dataToEdit.amount / +this.carbonData.kgPlasticWaste;
					}
				} else {
					carbonTemplate.averageWasteCardboard = 0;
					carbonTemplate.averageWastePaper = 0;
					carbonTemplate.averageWastePlastic = 0;
					carbonTemplate.averageWasteFood = 0;
					carbonTemplate.averageWasteGeneral = 0;
					carbonTemplate.averageWastePaint = 0;
					carbonTemplate.averageWasteWood = 0;
					carbonTemplate.averageWasteSteel = 0;
					carbonTemplate.averageWasteBatterie = 0;
				}
			} else if (this.carbonData.coTwoCategory === 2) {
				carbonTemplate.number = 1;
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / (this.carbonData.numberOfNight * this.carbonData.numberBedroom);
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 1) {
				if (this.dataToEdit.amount > 0) {
					const calcKwH = this.dataToEdit.amount / (this.carbonData.elecMeterEnd - this.carbonData.elecMeterStart);
					const calcGas = this.dataToEdit.amount / (this.carbonData.gasMeterEnd - this.carbonData.gasMeterStart);
					const calcWater = this.dataToEdit.amount / (this.carbonData.waterMeterEnd - this.carbonData.waterMeterStart);

					carbonTemplate.kwh = 1;

					carbonTemplate.averageElec = !_.isFinite(calcKwH) ? 0 : calcKwH;
					carbonTemplate.averageGas = !_.isFinite(calcGas) ? 0 : calcGas;
					carbonTemplate.averageWater = !_.isFinite(calcWater) ? 0 : calcWater;

					if (this.carbonData.generatorFuelType === 1) {
						const calcGenGas = this.dataToEdit.amount / this.carbonData.generatorVolumeUsed;
						carbonTemplate.averageGeneratorGas = !_.isFinite(calcGenGas) ? 0 : calcGenGas;
					} else if (this.carbonData.generatorFuelType === 2) {
						const calcGenUnlead = this.dataToEdit.amount / this.carbonData.generatorVolumeUsed;
						carbonTemplate.averageGeneratorUnlead = !_.isFinite(calcGenUnlead) ? 0 : calcGenUnlead;
					} else if (this.carbonData.generatorFuelType === 3) {
						const calcGenHyd = this.dataToEdit.amount / this.carbonData.generatorVolumeUsed;
						carbonTemplate.averageGeneratorHydrogene = !_.isFinite(calcGenHyd) ? 0 : calcGenHyd;
					}
				} else {
					carbonTemplate.averageElec = 0;
					carbonTemplate.averageGas = 0;
					carbonTemplate.averageWater = 0;
					carbonTemplate.averageGeneratorGas = 0;
					carbonTemplate.averageGeneratorUnlead = 0;
					carbonTemplate.averageGeneratorHydrogene = 0;
				}
			} else if (this.carbonData.coTwoCategory === 3) {
				if (this.dataToEdit.amount > 0) {
					const calcKwH = this.dataToEdit.amount / this.carbonData.kwh;
					const calcGas = this.dataToEdit.amount / this.carbonData.mcube;
					const calcWater = this.dataToEdit.amount / this.carbonData.waterVolumeUsed;
					const calcGenGas = this.dataToEdit.amount / this.carbonData.mcube;
					const calcGenUnlead = this.dataToEdit.amount / this.carbonData.liter;
					const calcGenHyd = this.dataToEdit.amount / this.carbonData.mcube;

					carbonTemplate.kwh = 1;

					carbonTemplate.averageElec = !_.isFinite(calcKwH) ? 0 : calcKwH;
					carbonTemplate.averageGas = !_.isFinite(calcGas) ? 0 : calcGas;
					carbonTemplate.averageWater = !_.isFinite(calcWater) ? 0 : calcWater;
					carbonTemplate.averageGeneratorGas = !_.isFinite(calcGenGas) ? 0 : calcGenGas;
					carbonTemplate.averageGeneratorUnlead = !_.isFinite(calcGenUnlead) ? 0 : calcGenUnlead;
					carbonTemplate.averageGeneratorHydrogene = !_.isFinite(calcGenHyd) ? 0 : calcGenHyd;
				} else {
					carbonTemplate.averageElec = 0;
					carbonTemplate.averageGas = 0;
					carbonTemplate.averageWater = 0;
					carbonTemplate.averageGeneratorGas = 0;
					carbonTemplate.averageGeneratorUnlead = 0;
					carbonTemplate.averageGeneratorHydrogene = 0;
				}
			} else if (this.carbonData.coTwoCategory === 4) {
				carbonTemplate.type = this.carbonData.type;
				carbonTemplate.subType = this.carbonData.subType;
				carbonTemplate.additionalSubType = this.carbonData.additionalSubType;
				carbonTemplate.number = this.carbonData.numberOfVehicule;
				carbonTemplate.km = this.carbonData.km;
				carbonTemplate.vehiculeId = this.carbonData.vehiculeId;
				carbonTemplate.reason = this.carbonData.reason;

				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.km;
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
					if (this.carbonData.boolValue === true) {
						carbonTemplate.km = this.carbonData.km;
						carbonTemplate.unitAmountTotal = 0;
					} else {
						carbonTemplate.km = 1;
					}
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 6) {
				carbonTemplate.subType = 1;
				carbonTemplate.mealVege = 1;
				carbonTemplate.mealRed = 0;
				carbonTemplate.mealWhite = 0;
				carbonTemplate.mealNotSpecified = 0;

				if (this.dataToEdit.amount > 0) {
					const calcMealVege = this.dataToEdit.amount / this.carbonData.mealVege;
					const calcMealRed = this.dataToEdit.amount / this.carbonData.mealRed;
					const calcMealWhite = this.dataToEdit.amount / this.carbonData.mealWhite;
					const calcMealNotSpecified = this.dataToEdit.amount / this.carbonData.mealNotSpecified;
					carbonTemplate.averageMeal = !_.isFinite(calcMealVege) ? 0 : calcMealVege;

					carbonTemplate.averageMealVege = !_.isFinite(calcMealVege) ? 0 : calcMealVege;
					carbonTemplate.averageMealRed = !_.isFinite(calcMealRed) ? 0 : calcMealRed;
					carbonTemplate.averageMealWhite = !_.isFinite(calcMealWhite) ? 0 : calcMealWhite;
					carbonTemplate.averageMealNotSpecified = !_.isFinite(calcMealNotSpecified) ? 0 : calcMealNotSpecified;
				} else {
					carbonTemplate.averageMealVege = 0;
					carbonTemplate.averageMealRed = 0;
					carbonTemplate.averageMealWhite = 0;
					carbonTemplate.averageMealNotSpecified = 0;
				}
			} else if (this.carbonData.coTwoCategory === 10) {
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.km;

					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 12) {
				carbonTemplate.km = 1;
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.km;
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			} else if (this.carbonData.coTwoCategory === 13) {
				carbonTemplate.liter = 1;
				if (this.dataToEdit.amount > 0) {
					const calc = this.dataToEdit.amount / this.carbonData.liter;
					carbonTemplate.unitAmountTotal = !_.isFinite(calc) ? 0 : calc;
				} else {
					carbonTemplate.unitAmountTotal = 0;
				}
			}

			return carbonTemplate;
		},
		handleCoTwoClapInitCarbon(payload) {
			this.dataToEdit.carbonValues = payload.carbonValues;
		},
		prepareImageUrls(images) {
			if (images.length > 0) {
				this.imageUrl = `${process.env.VUE_APP_GQL}/images/${images[0]}`;
				images.map((option) => {
					const extension = getFileExtension(option);
					this.imageUrls.push({
						hoverAction: false,
						imgSrc: `${process.env.VUE_APP_GQL}/images/${option}`,
						src: `${process.env.VUE_APP_GQL}/images/${option}`,
						fromDb: true,
						xid: option,
						file: null,
						extension,
						loading: extension === 'pdf'
					});
				});

				this.buildImagesList(this.imageUrls);
			}
		},
		async handleDeletePicture(index) {
			const action = async () => {
				if (this.imageUrls[index].fromDb === false) {
					this.imageUrls = this.imageUrls.filter((option) => option !== this.imageUrls[index]);
				} else {
					await this.$axios.$get(`/removeimage/${this.imageUrls[index].xid}`);
					this.imageUrls = this.imageUrls.filter((option) => option !== this.imageUrls[index]);
					this.$emit('additional-detail:remove-file', this.imageUrls[index].xid);
				}

				this.prepareFilesToInsert();
			};

			this.confirmModal(action, this.FormMSG(814, 'Are you sure ?'));
		},
		getFileExtensionB64(valueB64) {
			let mimeType = valueB64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];

			return mimeType;
		},
		getExtensionFileByXid(docXid) {
			return getFileExtension(docXid);
		},
		showPicture(index) {
			const images = this.imageUrls.map((img) => {
				if (img.fromDb === true) {
					return {
						xid: img.xid,
						src: `${process.env.VUE_APP_GQL}/images/${img.xid}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img.xid,
						ext: getFileExtension(img.xid)
					};
				} else {
					return {
						xid: '',
						src: img.imgSrc,
						thumb: img.imgSrc,
						ext: this.getFileExtensionB64(img.imgSrc)
					};
				}
			});

			this.$previewImages({
				images,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					// showStatusFileTag: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});

			this.emitEventClose();
		},
		handleMouseLeave(image) {
			image.hoverAction = false;
		},
		handleMouseEnter(image) {
			image.hoverAction = true;
		},
		prepareFilesToInsert() {
			let result = [];
			this.imageUrls.map((option) => {
				if (option.fromDb === false) {
					result.push(option.file);
				}
			});

			this.filesToInsert = result;
		},

		onFilePicked(e) {
			const files = e.target.files;
			let filename = files[0].name;
			const fileReader = new FileReader();
			console.log(files[0].name);
			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result;
				this.imageUrls.push({
					hoverAction: false,
					imgSrc: fileReader.result,
					fromDb: false,
					xid: '',
					file: files[0],
					extension: files[0].name.split('.')[1]
				});

				this.prepareFilesToInsert();
			});
			fileReader.readAsDataURL(files[0]);
			this.image = files[0];
		},
		onPickFile() {
			this.$refs['additional-detail-file'].click();
		},
		handleAdditionalDetailImagePicked(images) {
			this.filesToInsert = images;
		},
		handleCoTwoClapEndSubmit(payload) {
			this.$emit('review-dialog:submit', {
				data: {
					...payload
				}
			});
			this.loadingForSubmit = false;
			this.submitCarbon = false;
			this.emitEventClose();
		},
		handleAdditionalDetailChange(payload) {
			if (this.sourceType === 8) {
				this.dataToEdit.carbonDateTime = new Date(payload.carbonDateTime);
				this.dataToEdit.amount = payload.amount;
				this.dataToEdit.amountVat = payload.amountVat;
				this.dataToEdit.amountTotal = +payload.amount + +payload.amountVat;
				this.dataToEdit.supplierId = +payload.supplierId;
				this.dataToEdit.category = payload.category;
				this.dataToEdit.costCenter = payload.costCenter;
			}
		},
		handleMainDetailForReviewChange({ description, coTwo, forForecastReportOnly }) {
			this.dataToEdit.description = description;
			this.dataToEdit.coTwo = coTwo;
			this.dataToEdit.forForecastReportOnly = forForecastReportOnly;

			this.dataForCarbon.description = description;
		},
		emitEventShow() {
			this.$emit('review-dialog:show');
		},
		async handleOk(closed = true) {
			const coTwoClapInvalid = this.$refs['coTwoClapRef'].isInvalid();

			if (coTwoClapInvalid === true) return;

			if (this.isCreate === false) {
				this.loadingForSubmit = true;
				let resCarbonUpdated = {};

				if (this.dataToReview.entityType === 8) {
					let externalInput = {
						carbonDateTime: this.dataToEdit.carbonDateTime,
						description: this.dataToEdit.description,
						forForecastReportOnly: this.dataToEdit.forForecastReportOnly,
						amount: parseFloat(this.dataToEdit.amount),
						amountVat: parseFloat(this.dataToEdit.amountVat),
						amountTotal: parseFloat(this.dataToEdit.amountTotal),
						supplierId: this.dataToEdit.supplierId,
						category: this.dataToEdit.category,
						carbon: {
							...this.carbonData,
							carbonId: +this.dataToEdit.carbon.id
						}
					};

					const res = await addUpdCarbonFromExternalSource(+this.dataToReview.entityId, externalInput);
					resCarbonUpdated = res.carbon;
				} else {
					this.carbonData.entityType = this.dataToReview.entityType;
					this.carbonData.entityId = this.dataToReview.entityId;
					this.carbonData.amount = parseFloat(this.dataToReview.amount);
					this.carbonData.amountVat = parseFloat(this.dataToReview.amountVat);
					this.carbonData.amountTotal = parseFloat(this.dataToReview.amountTotal);
					this.carbonData.description = this.sourceType === 1 ? this.dataToEdit.description : this.dataToReview.description;
					this.carbonData.supplierId = this.dataToReview.supplierId;
					this.carbonData.forForecastReportOnly = this.dataToReview.forForecastReportOnly;
					this.carbonData.carbonDateTime = this.dataToReview.carbonDateTime;
					this.carbonData.carbonId = +this.dataToReview.id;

					resCarbonUpdated = await updCarbon(+this.dataToReview.id, { ...this.carbonData });
				}

				this.loadingForSubmit = false;

				if (this.filesToInsert.length > 0) {
					await this.insertFiles(+this.dataToReview.entityId);
				}

				this.createToastForMobile(this.FormMSG(415, 'Success'), this.FormMSG(812, 'Update successfully!'));

				this.emitEventUpdateSuccess({
					...resCarbonUpdated,
					carbonId: +resCarbonUpdated.id
				});

				// if (closed) {
				// 	this.emitEventClose();
				// }
			} else {
				try {
					this.loadingForSubmit = true;

					let externalInput = {
						carbonDateTime: this.dataToEdit.carbonDateTime,
						description: this.dataToEdit.description,
						// forForecastReportOnly: this.dataToEdit.forForecastReportOnly,
						amount: parseFloat(this.dataToEdit.amount),
						amountVat: parseFloat(this.dataToEdit.amountVat),
						amountTotal: parseFloat(this.dataToEdit.amountTotal),
						supplierId: this.dataToEdit.supplierId,
						category: this.dataToEdit.category,
						carbon: {
							...this.carbonData
							// TODO: ADD FIELD "forForecastReportOnly". forForeCastReportOnly: this.dataToEdit.forForeCastReportOnly
						}
					};

					const result = await addUpdCarbonFromExternalSource(0, externalInput);

					this.entityId = +result.carbon.entityId;

					if (this.filesToInsert.length > 0) {
						await this.insertFiles(+result.carbon.entityId);
					}

					this.createToastForMobile(this.FormMSG(415, 'Success'), this.FormMSG(416, 'External data added successfully!'));
					this.emitEventSubmit({
						...result.carbon,
						carbonId: +this.dataToEdit.carbon.id
					});
					this.emitEventClose();
				} catch (e) {
					console.log(e);
					this.loadingForSubmit = false;
				}
			}
		},
		emitEventSubmit(carbonData) {
			this.$emit('review-dialog:submit', {
				carbonData
			});
		},
		emitEventUpdateSuccess(carbonData) {
			this.$emit('review-dialog:update-success', {
				carbonData
			});
		},
		async insertFiles(externalId) {
			for (let i = 0; i < this.filesToInsert.length; i++) {
				const element = this.filesToInsert[i];
				let formData = new FormData();

				let parentType = 'carbon_from_external_source';

				if (this.sourceType === 2) {
					parentType = 'transport';
				}

				formData.append('uploadimage', element);
				formData.append('fileName', element.name);
				formData.append('fileType', element.type);
				formData.append('parentType', parentType);
				formData.append('parentId', externalId);

				let urlStr = process.env.VUE_APP_GQL + '/upload';

				await this.$axios.$post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				});
			}
		},
		async handleClickReviewed() {
			this.carbonData.verified = 3;
			await this.handleOk(false);

			this.initData();
			this.$emit('review-dialog:reviewed', {
				currentIndex: this.currentIndex - 1
			});
		},
		handleClickBefore() {
			this.initData();
			this.$emit('review-dialog:before', {
				currentIndex: this.currentIndex - 1
			});
		},
		handleClickAfter() {
			this.initData();
			this.$emit('review-dialog:after', {
				currentIndex: this.currentIndex - 1
			});
		},
		emitEventClose(refreshList = false) {
			const carbonData = _.cloneDeep(this.carbonData);
			this.initData();

			this.$emit('review-dialog:close', { refreshList, carbonData, currentIndex: this.currentIndex });
		},
		initData() {
			this.dataToEdit = _.cloneDeep(INIT_DATA_TO_EDIT);
			this.carbonData = _.cloneDeep(INIT_DATA_CARBON);
			this.isCreate = false;
			this.sourceType = 1;
			this.entityId = 0;
			this.loadingForMarkReview = false;
			this.loadingForSubmit = false;
			this.submitCarbon = false;
			this.dataForCarbon = { description: '' };
			this.dataLocationToUpload = null;
			this.fileToInsertExternal = null;
			this.filesToInsert = [];
			this.imageUrl = '';
			this.imageUrls = [];
			this.rawTextAutomation = {};
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />

<style>
.loading-text {
	color: #06263e;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.07rem;
	background-color: transparent;
}
</style>
