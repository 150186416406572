var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(75, "New entry options"),
        "header-class": "header-class-modal-doc-package",
        size: "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "form",
        { ref: "containerEntryOptionRef" },
        [
          _c(
            "b-form-radio",
            {
              attrs: { value: 0 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "fs-14 fw-700" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(48, "Without receipt")) +
                    "\n\t\t\t"
                ),
              ]),
            ]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "mt-3",
              attrs: { value: 1 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "fs-14 fw-700" }, [
                  _vm._v(_vm._s(_vm.FormMSG(158, "Main receipt")) + " -"),
                ]),
                _c("div", { staticClass: "fs-14 fw-500 ml-1" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(73, "Create one entry per receipt/invoice")
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "mt-3",
              attrs: { value: 2 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "fs-14 fw-700" }, [
                  _vm._v(_vm._s(_vm.FormMSG(101, "Detailed import")) + " -"),
                ]),
                _c("div", { staticClass: "fs-14 fw-500 ml-1" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          83,
                          "Create one entry per line of each receipt/invoice"
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.optionChoice === 1 || _vm.optionChoice === 2,
              },
            },
            [
              _vm.optionChoice === 1 || _vm.optionChoice === 2
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "10" } },
                            [
                              _c("b-form-file", {
                                attrs: { multiple: "" },
                                model: {
                                  value: _vm.filesToUpload,
                                  callback: function ($$v) {
                                    _vm.filesToUpload = $$v
                                  },
                                  expression: "filesToUpload",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "10" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { switch: "", size: "lg" },
                                  model: {
                                    value: _vm.runAutomation,
                                    callback: function ($$v) {
                                      _vm.runAutomation = $$v
                                    },
                                    expression: "runAutomation",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "fs-16 fw-700" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.FormMSG(
                                          514,
                                          "Run automation following import"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }